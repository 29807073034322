// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lr-drawer-link {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 23px;
}

.lr-drawer-body-desc {
  padding-bottom: 16px;
}
`, "",{"version":3,"sources":["webpack://./projects/lossreporting-library/src/lib/incident-details/incident-type/incident-type.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".lr-drawer-link {\n  display: flex;\n  flex-direction: column;\n  flex: 1 0 auto;\n  justify-content: flex-end;\n  align-items: center;\n  margin-bottom: 23px;\n}\n\n.lr-drawer-body-desc {\n  padding-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
