import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { CliffLogDetails } from '../models/cliff-log-details.model';
import { HostnameBuilderService } from './helpers/hostname-builder.service';
import { v1 as uuidv1 } from 'uuid';
import { LogDetails } from '../models/log-details.model';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  correlationId: string;
  traceLogId: string;
  response: HttpResponse<string>;
  externalClientId: string;
  cliffLog: CliffLogDetails;
  logDetails: LogDetails;

  constructor(private http: HttpClient, private hostnameBuilderService: HostnameBuilderService) {
    this.correlationId = uuidv1();
    this.logDetails = new LogDetails();
  }

  getLogDetails(): LogDetails {
    return this.logDetails;
  }

  setExternalClientId(extClientId: string): void {
    this.externalClientId = extClientId;
  }

  setClaimNumber(claimNumber: string): void {
    sessionStorage.setItem('claimNumber', claimNumber);
  }

  getClaimNumber(): string {
    return sessionStorage.getItem('claimNumber');
  }

  setClaimId(claimId: string): void {
    sessionStorage.setItem('claimId', claimId);
  }

  getClaimId(): string {
    return sessionStorage.getItem('claimId');
  }

  setFlowName(flowName: string): void {
    sessionStorage.setItem('flowPath', flowName);
  }

  getFlowName(): string {
    return sessionStorage.getItem('flowPath') || 'filealoss';
  }

  setEntryPoint(entryPoint: string): void {
    sessionStorage.setItem('entryPoint', entryPoint);
  }

  getEntryPoint(): string {
    return sessionStorage.getItem('entryPoint') || 'landing';
  }

  setOrigin(origin: string): void {
    sessionStorage.setItem('origin', origin);
  }

  getOrigin(): string {
    return sessionStorage.getItem('origin') || 'browser';
  }

  setOrgIndicator(type: string): void {
    if(type && type !== 'personal') {
      sessionStorage.setItem('orgIndicator', type);
    } else {
      sessionStorage.removeItem('orgIndicator');
    }
  }

  getOrgIndicator(): string {
    return sessionStorage.getItem('orgIndicator') || '';
  }

  getCorrelationId() {
    if(!this.correlationId){
      this.correlationId = uuidv1();
    }
    return this.correlationId;
  }

  sendLog(cliffLogDetails: CliffLogDetails) {
    cliffLogDetails.clientOrPartyId = this.externalClientId;
    cliffLogDetails.logData.flowName = this.getFlowName();
    cliffLogDetails.logData.consumer = this.getOrigin();
    cliffLogDetails.logData.orgIds = this.getOrgIndicator();
    cliffLogDetails.claimId = this.getClaimId();
    cliffLogDetails.claimNum = this.getClaimNumber();
    cliffLogDetails.entryPoint = this.getEntryPoint();
    const body = JSON.stringify(cliffLogDetails);

    return this.http
    .post(this.hostnameBuilderService.getCLIFFUrl().url, body, { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': this.hostnameBuilderService.getCLIFFUrl().apiKey,
        'correlationId': this.getCorrelationId(),
        'applicationId': 'File-A-Loss'
      })
    })
    .subscribe(res => {}, error => {});
  }

  log(logCode: any, logDetails: LogDetails = null): void {
    this.cliffLog = new CliffLogDetails(logCode.id);
    this.cliffLog.logData = logDetails ? logDetails : this.getLogDetails();
    this.cliffLog.message = logCode.text;
    this.cliffLog.logData.serviceName = logCode.svcName;

    this.sendLog(this.cliffLog);
  }

}
