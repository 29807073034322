import { Component, OnInit, ViewChild, Inject, ElementRef, Input } from '@angular/core';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { Participant } from '../../models/participant.model';
import { Router } from '@angular/router';
import { BaseComponent } from '../../shared/base/base.component';
import { LossReportService } from '../../services/loss-report.service';
import { RoutingService } from '../../services/routing.service';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';
import { LogService } from '../../services/log.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { InsuredParticipantTrackingService } from '../../services/insured-participant-tracking.service';
import { LossreportAnsweredStepBuilderService } from '../../services/helpers/lossreport-answered-step-builder.service';
import { ParticipantBuilderService } from '../../services/helpers/participant-builder.service';
import { NameComponent } from '../../shared/name/name.component';
import { ContactComponent } from '../../shared/contact/contact.component';
import { LogDetails } from '../../models/log-details.model';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { Contact } from '../../models/contact.model';
import { ClaimsEventsService } from '../../services/claims-events.service';


@Component({
  selector: 'olr-additional-insured-participants',
  templateUrl: './additional-insured-participants.component.html',
  styleUrls: ['./additional-insured-participants.component.css', '../../shared/styles/question-common-styling.scss']
})
export class AdditionalInsuredParticipantsComponent extends BaseComponent implements OnInit {
  @ViewChild(NameComponent) nameComponent: NameComponent;
  @ViewChild(ContactComponent) contactComponent: ContactComponent;
  isAutopopulated = false;
  householdIndicator = 'no';
  vehicle: string;
  participants: Participant[] = [];
  currentParticipant: Participant;
  editMode = false;
  addMode = false;
  updating = false;
  isAnswerSelected = false;
  isValueProvided = false;
  isValue = true;
  isValid = false;
  isHouseholdIndicator = true;
  isParticipantInfoComplete = false;
  nameObject: any;
  firstName: '';
  lastName: '';
  contactObject: any;
  loggedInCustomer = [];
  loggedInCustomerRole = '';
  policyParticipants: Participant[] = [];
  injuryStatusOptions = ['yes', 'no', 'unknown'];
  medicalOptions = ['yes', 'no', 'unknown'];
  lossOfLifeOptions = ['yes', 'no'];
  householdOptions = ['Yes', 'No'];
  airbagDeployOptions = ['yes', 'no', 'unknown', 'na'];
  selectedCustomerName = '';
  selectedRole = '';
  selectedInjuryStatus = '';
  selectedLossOfLife = '';
  selectedInjuries = [];
  selectedMedicalTreatment = '';
  selectedAirbagDeployed = '';
  selectedHousehold = 'no';
  street = '';
  city = '';
  state = '';
  postalCode = '';
  autopopulateData: Contact = new Contact();
  logDetails: LogDetails;


  constructor(
    @Inject('Window') private window: any,
    private trackingService: InsuredParticipantTrackingService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    private analytics: SfAnalyticsService,
    private participantBuilder: ParticipantBuilderService,
    private customerProxyService: CustomerProxyService,
    private lossreportanswerfinderservice: LossReportAnswerFinderService,
    public lossReportService: LossReportService,
    public routingService: RoutingService,
    public router: Router,
    public logService: LogService,
    private stepBuilderService: LossreportAnsweredStepBuilderService,
    private eventsService: ClaimsEventsService,
  ) {
    super(lossReportService, router, routingService, logService);
  }

  ngOnInit(): void {
    this.logDetails = this.logService.getLogDetails();
    this.getLoggedInCustomerInfo();
    this.getPolicyCustomers();
    this.getPolicyAddress();
    this.vehicle = this.policyVehicleInfoService.getVehicleDescription();
    this.participants = this.trackingService.getAdditionalParticipants();
    this.setupEditForPassedAdditionalParticipant();
    this.setupAddNewAdditionalParticipant();
    this.analytics.sendData(`insured-vehicle-add-participant${this.participants.lastIndexOf(this.currentParticipant) + 1}`);
  }

  get hideAddAdditionalLink(): boolean {
    return this.participants.length === 10;
  }

  get displayRemainingQuestions(): boolean {
    return this.selectedInjuryStatus === 'yes' && this.selectedLossOfLife === 'no';
  }

  private getLoggedInCustomerInfo() {
    this.customerProxyService.getCustomerInfo();
    this.customerProxyService.customerInfoUpdated.subscribe( response => {
      this.loggedInCustomer[0] = [`${response.name.firstName}`];
      this.loggedInCustomer[1] = [`${response.name.lastName}`];
    });
    const insuredRoleQuestion = this.lossReportService.getStepByName('insuredInvolvement');
    this.loggedInCustomerRole = insuredRoleQuestion.question.subQuestions[0][0].answers[0];
  }

  private getPolicyCustomers() {
    this.policyParticipants = this.trackingService.getParticipants();
  }

  private setupEditForPassedAdditionalParticipant(): void {
    const participant = this.trackingService.getPassedAdditionalParticipant();
    if (participant) {
      this.trackingService.setPassedAdditionalParticipant(null);
      this.editParticipantInvolvement(participant);
    }
  }

  private setupAddNewAdditionalParticipant(): void {
    const triggered = this.trackingService.getAddNewParticipantTrigger();
    if (triggered) {
      this.trackingService.setAddNewParticipantTrigger(false);
      this.addAnotherParticipant();
    }
  }

  private resetModes(role: string): void {
    this.editMode = false;
    this.addMode = false;
    setTimeout(() => {
      this.window.oneX.addElement('#edit-person');
      this.activateFloatingLabel();
    }, 100);
  }

  editLoggedInUserInvolvement(){
    this.router.navigate(['/incident-details/involvement']);
  }

  findAnswer(question: string){
    return this.lossreportanswerfinderservice.findAnswer(question);
  }

  updateName(answer: any): void {
    this.isValueProvided = Object.keys(answer).length !== 0 && answer.firstName.length > 0 && answer.lastName.length > 0;
    this.firstName = answer.firstName;
    this.lastName = answer.lastName;
    this.selectedRole = this.findAnswer('insuredInvolvement.insuredRole');
    if (this.selectedRole === 'driver') {
      this.isParticipantInfoComplete = this.isValueProvided;
      this.isHouseholdIndicator = false;
    }
    if(this.selectedRole === 'passenger'){
      this.isParticipantInfoComplete = this.isValueProvided
      this.isHouseholdIndicator = false;

    }
    if (this.isValueProvided) {
      this.nameObject = answer;
    }
  }


  updateHousehold(answer: any): void {
    this.selectedHousehold = answer.toLowerCase();
    if (this.isHouseholdIndicator) {
      this.householdOptions = answer.toLowerCase();
      this.contactComponent.ngOnInit();
    }
  }

  updateContact(answer: any): void {
    if (this.isParticipantInfoComplete) {
      this.contactObject = answer;
    }
  }

  isInformationDone(answer: boolean): void {
    this.isValid = answer;
  }

  private activateFloatingLabel(){
    setTimeout(() => {
      // @ts-ignore
      this.window.oneX.addElement('#dropdowns');
    }, 100);
  }

  private toggleContinue() {
    const initialQuestionsAnswered = (this.selectedRole === 'driver' || this.selectedRole === 'passenger') && this.selectedInjuryStatus.length > 0;
    const lossOfLifeAnswered = this.selectedInjuryStatus === 'yes' ? this.selectedLossOfLife.length > 0 : true;
    this.isAnswerSelected = initialQuestionsAnswered && lossOfLifeAnswered;
    this.activateFloatingLabel();
  }

  updateRole(answer: any) {
    this.selectedRole = answer;
    this.toggleContinue();
  }

  updateInjuryStatus(answer: any) {
    this.selectedInjuryStatus = answer;
    if (answer === 'no') {
      this.selectedLossOfLife = ''; // resets in the event of a change of mind.
    }
    this.toggleContinue();
  }

  updateLossOfLife(answer: any) {
    this.selectedLossOfLife = answer;
    this.toggleContinue();
  }

  updateInjuries(answer: any) {
    this.selectedInjuries = answer;
  }

  updateMedicalTreatment(answer: any) {
    this.selectedMedicalTreatment = answer;
  }

  updateAirbagDeploy(answer: any) {
    this.selectedAirbagDeployed = answer;
  }

  createUnknownParticipant(): void {
    this.buildParticipant(null, null);
  }

  createParticipant(): void {
    this.buildParticipant(this.nameObject, this.contactObject);
  }

  private buildParticipant(nameObject: any, contactObject: any) {
    const role = this.editMode ? this.currentParticipant.role : undefined;
    this.isAnswerSelected = this.editMode;
    const options = this.editMode && this.currentParticipant.role === 'driver' ? this.currentParticipant.options : this.determineOptions();
    if (!this.updating) {
      this.currentParticipant = this.participantBuilder.createParticipant(null, role, nameObject, contactObject);
      this.currentParticipant.options = options;
      this.currentParticipant.isHouseholdParticipant = this.selectedHousehold;
    } else {
      this.currentParticipant = this.participantBuilder.updateParticipant(this.currentParticipant, nameObject, contactObject);
    }
    this.resetModes(role);
  }

  private determineOptions(): string[] {
    return this.trackingService.isDriverSelected ? ['passenger'] : ['driver', 'passenger'];
  }

  saveParticipant(): void {
    this.currentParticipant.role = this.selectedRole;
    this.currentParticipant.injuryStatus = this.selectedInjuryStatus;
    this.currentParticipant.lossOfLife = this.selectedLossOfLife;
    if (this.displayRemainingQuestions) {
      this.currentParticipant.injuries = this.selectedInjuries;
      this.currentParticipant.medicalTreatment = this.selectedMedicalTreatment;
      this.currentParticipant.airbagDeploy = this.selectedAirbagDeployed;
      this.selectedInjuries = [];
      this.selectedAirbagDeployed = '';
      this.selectedMedicalTreatment = '';
    } else {
      this.currentParticipant.injuries = [];
      this.currentParticipant.medicalTreatment = '';
      this.currentParticipant.airbagDeploy = '';
    }

    if (!this.updating) {
      if (this.currentParticipant.name) {
        this.currentParticipant.name.first = this.removeAccents(this.currentParticipant.name.first)
        this.currentParticipant.name.last = this.removeAccents(this.currentParticipant.name.last)
      }
      if (this.currentParticipant.contact) {
        this.currentParticipant.contact.street1 = this.removeAccents(this.currentParticipant.contact.street1)
        this.currentParticipant.contact.street2 = this.removeAccents(this.currentParticipant.contact.street2)
        this.currentParticipant.contact.city = this.removeAccents(this.currentParticipant.contact.city)
        this.currentParticipant.contact.state = this.removeAccents(this.currentParticipant.contact.state)
      }

      this.participants.push(this.currentParticipant);
    } else {
      this.updating = false;
    }
    this.trackingService.refreshParticipantsList(this.participants, 'additionalParticipants');
    this.trackingService.updatePolicyParticipants();
    this.analytics.sendData(`insured-vehicle-add-participant${this.participants.lastIndexOf(this.currentParticipant) + 1}`);
    this.getPolicyCustomers();
    this.currentParticipant = undefined;
    this.isValueProvided = false;
    this.selectedRole = '';
    this.selectedLossOfLife = '';
    this.selectedInjuryStatus = '';
  }

  removeParticipant() {
    this.selectedCustomerName = this.currentParticipant.fullName || 'the person';
    this.analytics.sendData('remove-participant-modal');
    this.window.oneX.Modal.showModal(this, 'olr-modal-remove-participant');
  }

  modalAnswer(answer: string) {
    if (answer === 'yes') {
      this.participants = this.participants.filter(participant => participant.id !== this.currentParticipant.id);
      this.currentParticipant = null;
      this.updating = false;
      this.isValueProvided = false;
      this.selectedRole = '';
      this.selectedInjuryStatus = '';
      this.selectedLossOfLife  = '';
      this.selectedInjuries = [];
      this.selectedMedicalTreatment = '';
      this.selectedAirbagDeployed = '';
      this.trackingService.refreshParticipantsList(this.participants, 'additionalParticipants');
    } else {
      this.isAnswerSelected = this.selectedRole.length > 0
      && this.selectedInjuryStatus.length > 0
      && this.selectedLossOfLife.length > 0;
    }
  }

  editParticipant(participant: Participant): void {
    this.currentParticipant = participant;
    this.editMode = true;
    setTimeout(() => {
      if (!this.currentParticipant.name.unknown) {
        this.nameComponent.setAnswerState(this.currentParticipant.name);
        this.contactComponent.setAnswerState(this.currentParticipant.contact);
        this.householdIndicator = this.currentParticipant.isHouseholdParticipant;
      }
      this.toggleContinue();
    }, 100);
  }

  editParticipantInvolvement(participant: Participant): void{
    this.currentParticipant = participant;
    this.updating = true;
    this.selectedRole = this.currentParticipant.role;
    this.selectedInjuryStatus = this.currentParticipant.injuryStatus;
    this.selectedLossOfLife  = this.currentParticipant.lossOfLife;
    this.selectedInjuries = this.currentParticipant.injuries;
    this.selectedMedicalTreatment = this.currentParticipant.medicalTreatment;
    this.selectedAirbagDeployed = this.currentParticipant.airbagDeploy;
    setTimeout(() => {
      this.toggleContinue();

    }, 10);
  }

  editPolicyParticipant(participant: Participant): void {
    this.trackingService.setPassedPolicyParticipant(participant);
    this.router.navigate(['/incident-details/insured-participant-details']);
  }

  addAnotherParticipant(): void {
    this.addMode = true;
    this.isParticipantInfoComplete = false;
    setTimeout(() => {

    }, 100);
  }

  getPolicyAddress(): void {
    this.customerProxyService.customerInfoUpdated.subscribe( response => {
      const address = response.mailingAddress;
      if (address) {
        this.street = address.addressLines[0];
        this.city = address.city;
        this.state = address.stateProvince;
        this.postalCode = address.postalCode;
        this.postalCode = this.postalCode ? this.postalCode.substring(0, 5) : '';
      }

    });
  }

  autopopulate(answer: any): void {
    if ('Yes' === answer) {
      this.autopopulateData.street1 = this.street;
      this.autopopulateData.city = this.city;
      this.autopopulateData.state = this.state;
      this.autopopulateData.zipCode = this.postalCode;
      this.autopopulateData.firstName = this.firstName
      this.autopopulateData.lastName = this.lastName;
      this.contactComponent.setAnswerState(this.autopopulateData);
      this.isParticipantInfoComplete = this.isValueProvided;
      this.isAutopopulated = true;
    }
    else if ('No' === answer) {
      this.autopopulateData.street1 = '';
      this.autopopulateData.street2 = '';
      this.autopopulateData.city = '';
      this.autopopulateData.state = '';
      this.autopopulateData.zipCode = '';
      this.autopopulateData.firstName = this.firstName;
      this.autopopulateData.lastName = this.lastName;
      this.contactComponent.setAnswerState(this.autopopulateData);
      this.isParticipantInfoComplete = this.isValueProvided;
      this.isAutopopulated = false;
    }
    else{
      this.isParticipantInfoComplete = false;
      this.isAutopopulated = false;
    }
  }

  removeAccents(originalText: string): string {
    var returnText = ''
    if ((originalText != null) && (originalText != undefined)) {
      returnText = originalText.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    }
    return returnText
  }

  answerQuestion(){
    this.logDetails.numberOfAdditionalParticipants = `${this.participants.length}`;
    this.logService.log(CliffLogCodes.ADDL_PRT_ADD, this.logDetails);
    if (this.policyParticipants.length > 0){
      const insuredParticipantsStep = this.lossReportService.getStepByName('insuredVehicleParticipants');
      insuredParticipantsStep.question.subQuestions = this.stepBuilderService.answerPolicyInsuredParticipants(this.policyParticipants);
      insuredParticipantsStep.completed = true;
    }
    this.updateSubQuestions(this.stepBuilderService.answerAdditionalInsuredParticipants(this.participants));
  }

}
