import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { LossReportService } from '../../services/loss-report.service';

import { Router } from '@angular/router';
import { SfAnalyticsService } from '../../services/sf-analytics.service';
import { LossReportAnswerFinderService } from '../../services/helpers/loss-report-answer-finder.service';
import { CustomerProxyService } from '../../services/customer-proxy.service';
import { PolicyVehicleInfoService } from '../../services/policy-vehicle-info.service';
import { ExteriorDamageService } from '../../damage-details/exterior-damage/exterior-damage.service';
import { ExteriorDamageCounter } from '../../damage-details/exterior-damage/exterior-damage-counter.model';
import { InsuredParticipantTrackingService } from '../../services/insured-participant-tracking.service';
import { ClaimantParticipantTrackingService } from '../../services/claimant-participant-tracking.service';
import * as moment from 'moment';
import { Participant } from '../../models/participant.model';
import { Subscription } from 'rxjs';
import { RepairEligibilityService } from '../../services/repair.eligibility.service';
import { ClaimDetailsService } from '../../services/claim-details.service';
import { LogService } from '../../services/log.service';
import { CliffLogCodes } from '../../shared/constants/cliff-log-constants';
import { ClaimsEventsService } from '../../services/claims-events.service';
import { toHash } from 'ajv/dist/compile/util';
import { hostname } from 'os';

@Component({
  selector: 'olr-review-claim',
  templateUrl: './review-claim.component.html',
  styleUrls: ['./review-claim.component.css', '../../shared/styles/question-common-styling.scss']
})
export class ReviewClaimComponent implements OnInit, OnDestroy {
  answers: string;
  customerInfo: any;
  hasSubmitted = false;
  damagedAreas: ExteriorDamageCounter[] = [];
  otherDamageAreas: string[] = [];
  hasExteriorDamage = false;
  myDate: string;
  customers = [];
  firstClaimantVehicleParticipants = [];
  additionalParticipants: Participant[] = [];
  witnessName: any;
  dateOfLossRoute: string;
  policyNumberRoute: string;
  repairEligibilitySubscription$: Subscription;
  isLoading = false;
  lossReportUpdatedSubscription$: Subscription;
  isSimpleSubtype: boolean;
  isSimpleSingleVehicle: boolean;

  constructor(
    private sfAnalyticsService: SfAnalyticsService,
    public lossReportService: LossReportService,
    public router: Router,
    private lossreportanswerfinderservice: LossReportAnswerFinderService,
    private customerProxyService: CustomerProxyService,
    private policyVehicleInfoService: PolicyVehicleInfoService,
    private exteriorDamageService: ExteriorDamageService,
    private insuredParticipantTrackingService: InsuredParticipantTrackingService,
    private claimantParticipantTrackingService: ClaimantParticipantTrackingService,
    private repairEligibilityService: RepairEligibilityService,
    private claimDetailsService: ClaimDetailsService,
    private logService: LogService,
    @Inject('Window') private window: Window,
    private claimsEventsService: ClaimsEventsService
  ) { }

  ngOnInit() {
    this.claimsEventsService.buildEvent('Review Claim');
    this.setDynamicRouteInfo();
    this.sfAnalyticsService.sendData('review-submit-page');
    this.customerInfo = this.customerProxyService.customerInfo;
    this.customers = this.insuredParticipantTrackingService.getParticipants();
    this.insuredParticipantTrackingService.getAdditionalParticipants().forEach((participant: Participant) => {
      this.customers.push(participant);
    });
    this.firstClaimantVehicleParticipants = this.claimantParticipantTrackingService.getClaimantParticipants('firstClaimantVehicleParticipants');
    this.getDamageDisplay();
    this.additionalParticipants = this.insuredParticipantTrackingService.getAdditionalParticipants();
    this.witnessName = this.findAnswer('witnessInformation.witnessInformationName');
    this.setIsSimpleSubtype();
    this.setIsSingleVehicle();
  }

  ngOnDestroy(): void {
      if (this.lossReportUpdatedSubscription$) this.lossReportUpdatedSubscription$.unsubscribe();
  }

  private unsubscribe(subscription: Subscription): void {
    if (subscription) { subscription.unsubscribe(); }
  }

  private setDynamicRouteInfo(): void {
    const dateOfLossStep = this.lossReportService.getStepByName('dateOfLoss');
    this.dateOfLossRoute = dateOfLossStep?.title;
    const policyNumberStep = this.lossReportService.getStepByName('policyNumber');
    this.policyNumberRoute = policyNumberStep?.title;
  }

  submitClaim() {
    if (!this.hasSubmitted) {
      this.hasSubmitted = true;
      this.lossReportService.submitReport();
      this.isLoading = true;
      this.router.navigate(['/error/claim-already-submitted']).then(() => {
        this.lossReportUpdatedSubscription$ = this.lossReportService.lossReportUpdated.subscribe(() => {
          const claim = this.claimDetailsService.getClaimDetails();

          const externalClientId = this.sfAnalyticsService.getExternalClientId();
          this.repairEligibilitySubscription$ = this.repairEligibilityService.getRepairEligibility(claim.id, externalClientId).subscribe(response => {
           if (response.eligibility_decision) {
            if(this.isSimpleSingleVehicle && this.isSimpleSubtype){
              this.repairEligibilityService.setSimpleRepair(true);
              this.window['dataLayer'].isSimpleRepairAB = true;
            }else{
              this.repairEligibilityService.setSimpleRepair(false);
              this.window['dataLayer'].isSimpleRepairAB = false;
            }

            this.window['dataLayer'].isRepairEligible = response.eligibility_decision
            this.window['dataLayer'].role = response.role
            this.window['dataLayer'].participantId = response.participant_id
            this.logService.log(CliffLogCodes.REPAIR_ELIG_VALUE_TRUE);
          } else {
            this.repairEligibilityService.setSimpleRepair(false);
            this.window['dataLayer'].isRepairEligible = false;
            this.window['dataLayer'].isSimpleRepairAB = false;
            this.logService.log(CliffLogCodes.REPAIR_ELIG_VALUE_FALSE);
          }
          this.isLoading = false;
          this.router.navigate(['/confirmation/received-auto-claim']);
          this.unsubscribe(this.repairEligibilitySubscription$);
          });
        });
      });
    }
  }


  routeToEdit(route: string) {
    if (route){
      this.router.navigate([route]);
    }
  }

  findAnswer(question: string){
    return this.lossreportanswerfinderservice.findAnswer(question);
  }

  setIsSimpleSubtype(){
    const incidentDetails = this.lossReportService.getStepByName('incidentSubCategory');
    const subQuestion = incidentDetails ? incidentDetails.question.answers[0] : '';
    if (subQuestion !== '') {
      this.isSimpleSubtype = (subQuestion === 'animal' || subQuestion === 'stationaryObject' || subQuestion === 'potholeDebris');
    }
  }

  setIsSingleVehicle(){
    const incidentDetails = this.lossReportService.getStepByName('maxVehiclesCount');
    const subQuestion = incidentDetails ? incidentDetails.question.answers[0] : '';
    if (subQuestion !== '') {
      this.isSimpleSingleVehicle = subQuestion === '1';
    }
  }

  getCustomerEmail(){
    return this.customerInfo ? this.customerInfo.email : '';
  }

  getOrgEmail(): string {
    const orgContactInformationStep = this.lossReportService.getStepByName('orgContactInformation');
    const attributes = orgContactInformationStep?.question.displayContent || [];
    return this.getKeyValue(attributes, 'email') || 'No email address added.'
  }

  getOrgAddress() {
    const orgContactInformationStep = this.lossReportService.getStepByName('orgContactInformation');
    const attributes = orgContactInformationStep?.question.displayContent || [];

    const street1 = this.getKeyValue(attributes, 'address', 'street1');
    const street2 = this.getKeyValue(attributes, 'address', 'street2');
    const city = this.getKeyValue(attributes, 'address', 'city');
    const stateProvince = this.getKeyValue(attributes, 'address', 'state');
    const postalCode = this.getKeyValue(attributes, 'address', 'postalCode');

    if(street1 && city && stateProvince && postalCode) {
      return {
        line1: `${street1}`,
        line2: `${street2}`,
        line3: `${city}, ${stateProvince} ${postalCode}`
      };
    } else {
      return {line1: '', line2: '', line3: ''};
    }
  }

  getOrgName(): string {
    const orgSelectionStep = this.lossReportService.getStepByName('orgSelection');
    const attributes = orgSelectionStep?.question.displayContent || [];
    const orgSelectionAns = orgSelectionStep?.question.answers[0] || '';
    return this.getKeyValue(attributes, orgSelectionAns, 'name');
  }

  getCustomerName(): string {
    if (this.customerInfo) {
      const name = this.customerInfo.name;
      return  `${name.firstName} ${name.lastName}`;
    } else {
      return '';
    }
  }

  getWitnessName(): string {
    if (this.witnessName) {
      return  `${this.witnessName.firstName} ${this.witnessName.lastName}`;
    } else {
      return '';
    }
  }

  getCustomerAddress(): any {
    if (this.customerInfo) {
      const address = this.customerInfo.mailingAddress;
      return {
        line1: `${address.addressLines[0]}`,
        line2: `${address.addressLines[1]}`,
        line3: `${address.city}, ${address.stateProvince} ${address.postalCode}`
      };
    } else {
      return {line1: '', line2: '', line3: ''};
    }
  }

  getSelectedPolicyVehicle(){
    return this.policyVehicleInfoService.getSelectedPolicyVehicle();
  }

  hasMoreThanTwoVehicles(value: number){
    return value > 2;
  }

  hasMoreThanOneVehicle(value: number){
    return value > 1;
  }

  hasAdditionalParticipants(){
    return this.additionalParticipants.length;
  }

  getDamageDisplay(){
    this.damagedAreas = this.exteriorDamageService.getDamageCounters().filter(dc => dc.counter > 0);
    const damageLocationStep = this.lossReportService.getStepByName('damages');
    if (damageLocationStep){
      const damageLocations = {
        interior: 'Interior',
        attachedTrailer: 'Attached Trailer',
        waterDamage: 'Water Damage',
        undercarriage: 'Underside',
        airbags: 'Air Bags',
      };
      damageLocationStep.question.answers.forEach((answer) => {
        if (answer !== 'exterior') {
          this.otherDamageAreas.push(damageLocations[answer]);
        }
        else{
          this.hasExteriorDamage = true;
        }
      });
    }
  }

  getFormattedDate(){
    this.myDate = this.findAnswer('dateOfLoss');
    const formattedDate = moment.utc(this.myDate).format('MM/DD/YYYY');
    return formattedDate;
  }

  private getKeyValue(attributes: any[], key: string, subKey: string = ''): string {
    let found: any;

    for (const attribute of attributes) {
      if (attribute.key === key) {
        found = attribute;
        break;
      }
    }
    if (subKey && found) {
      for (const foundValue of found.value) {
        if (foundValue.key === subKey) {
          return foundValue.value[0];
        }
      }
    }
    return found ? found.value[0] : '';
  }

}


